/**
 * Costanti per la navigazione nel menu
 */
export const APP_LAYOUT = '';
export const APP_LOGIN = 'login';
export const APP_REPORTING = 'reporting';
export const APP_HOTEL = 'hotel';
export const APP_CUSTOMER = 'customer';
export const APP_COMMERCIAL_MANAGEMENT = 'commercial-management';
export const APP_HOTEL_CHAIN = 'hotel-chain';
export const APP_AIRPORTS = 'airports';
export const APP_CHANNEL_MANAGER = 'channel-manager';
export const APP_USER = 'user';

export const QUERY_LIST = 'list';
export const COMMAND_EDIT = 'edit';

export const DATE_FILTER = 'DDMMYYYY';
